.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Waves css start */
@import url(//fonts.googleapis.com/css?family=Lato:300:400);

.wavesheader {
  position:relative;
  text-align:center;
  background: linear-gradient(60deg, rgb(71, 33, 223) 0%, rgb(143, 0, 238) 100%);
  color:white;
}

.testColor{
  color: rgb(71, 33, 223) 0%;
}


.wavesinner-header {
  height:65vh;
  width:100%;
  margin: 0;
  padding: 0;
}

.wavesflex { /*Flexbox for containers*/
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.waves {
  position:relative;
  width: 100%;
  height:15vh;
  margin-bottom:-7px; /*Fix for safari gap*/
  min-height:100px;
  max-height:150px;
}

.wavescontent {
  position:relative;
  height:20vh;
  text-align:center;
  background-color: white;
}

/* Animation */

.wavesparallax > use {
  animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
}
.wavesparallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.wavesparallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.wavesparallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.wavesparallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
   transform: translate3d(-90px,0,0);
  }
  100% { 
    transform: translate3d(85px,0,0);
  }
}
/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height:40px;
    min-height:40px;
  }
  .wavescontent {
    height:30vh;
  }
}

/* Waves css end */


/* Services glassmorphism start */

technicalservicebody {
  background: #1f1f47
}

.technicalservicecontainer {
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.technicalservicecard {
  
  background: rgba( 255, 255, 255, 0.15 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 18px );
  -webkit-backdrop-filter: blur( 18px );
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  border-radius: 1rem;
  padding: 1.5rem;
  z-index: 10;
  color: whitesmoke;
  margin:5%;
}

.technicalservicetitle {
  font-size: 2.2rem;
  margin-bottom: 1rem;
}

.technicalservicesubtitle {
  font-size: 1rem;
  margin-bottom: 2rem;
}

.technicalservicebtn {
  background: none;
  border: none;
  text-align: center;
  font-size: 1rem;
  color: whitesmoke;
  background-color: #fa709a;
  padding: 0.8rem 1.8rem;
  border-radius: 2rem;
  cursor: pointer;
}

.technicalserviceblob {
  position: absolute;
  width: 500px;
  height: 500px;
  background: linear-gradient(
    180deg,
    rgba(47, 184, 255,0.42) 31.77%,
    #5c9df1 100%
  );
  mix-blend-mode: color-dodge;
  -webkit-animation: move 25s infinite alternate;
          animation: move 25s infinite alternate;
  transition: 1s cubic-bezier(0.07, 0.8, 0.16, 1);
}

.technicalserviceblob:hover {
  width: 520px;
  height: 520px;
  -webkit-filter: blur(30px);
          filter: blur(30px);
  box-shadow:
    inset 0 0 0 5px rgba(255,255,255, 0.6),
    inset 100px 100px 0 0px #fa709a,
    inset 200px 200px 0 0px #784ba8,
    inset 300px 300px 0 0px #2b86c5;
}

@-webkit-keyframes move {
  from {
    transform: translate(-100px, -50px) rotate(-90deg);
    border-radius: 24% 76% 35% 65% / 27% 36% 64% 73%;
  }

  to {
    transform: translate(500px, 100px) rotate(-10deg);
    border-radius: 76% 24% 33% 67% / 68% 55% 45% 32%;
  }
}

@keyframes move {
  from {
    transform: translate(-100px, -50px) rotate(-90deg);
    border-radius: 24% 76% 35% 65% / 27% 36% 64% 73%;
  }

  to {
    transform: translate(500px, 100px) rotate(-10deg);
    border-radius: 76% 24% 33% 67% / 68% 55% 45% 32%;
  }
}
/* Services glassmorphism end */

/* Particle animation start */
/* Particle animation end */


/* Navigation popover start */

/* Navigation popover end */



/* Logo SVG styles */

.cls-1 {
  font-size: 118.84px;
fill: #a9bccd;
font-family: Poppins-Bold, Poppins;
font-weight: 700;
letter-spacing: 0.1em;
  }

.cls-2 {
  fill: url(#Degradado_sin_nombre_950);
  }

.cls-3 {
  fill: url(#Degradado_sin_nombre_926);
  }

.cls-4 {
  fill: url(#linear-gradient);
  }

.cls-5 {
  fill: url(#linear-gradient-2);
  }

.cls-6 {
  fill: url(#Degradado_sin_nombre_926-2);
  }

.cls-7 {
  fill: url(#linear-gradient-3);
  }

.cls-8 {
  fill: url(#linear-gradient-4);
  }

.cls-9 {
  fill: url(#linear-gradient-5);
  }

.cls-10 {
  fill: url(#linear-gradient-6);
  }



  .contact-card-container{
    height: 400px;
  }